<template>
	<v-container fluid>
		<v-row class="mx-md-2">
			<v-col cols="12">
				<v-card v-bind="customCard">
					<v-data-table :headers="headers" :items="products.data" :loading="listStatus === status.RUNNING"
								  disable-filtering disable-pagination disable-sort hide-default-footer
								  loading-text="Loading... Please wait">
						<template v-slot:top>
							<v-toolbar id="table-toolbar" class="rounded-t" flat>
								<v-toolbar-title>
									{{ filters.archived ? 'Archived Products' : 'Products' }}
								</v-toolbar-title>

								<v-spacer></v-spacer>

								<v-btn :disabled="filters.archived" @click="" class="mr-2" color="primary">
									<v-icon>
										mdi-plus
									</v-icon>
								</v-btn>

								<table-filters v-model="filters" :filter-types="filterTypes"
											   right-margin></table-filters>

								<v-btn @click="refreshData" color="primary">
									<v-icon>
										mdi-refresh
									</v-icon>
								</v-btn>
							</v-toolbar>
						</template>

						<template v-slot:item.name="{item}">
							<div v-if="!filters.archived" @click="editProduct(item.id)"
								 class="text-decoration-underline clickable-text">
								{{ item.name }}
							</div>
							<div v-else>{{ item.name }}</div>
						</template>

						<template v-slot:item.category="{item}">
							{{ categoryNameForId(item.categoryId) }}
						</template>

						<template v-slot:item.active="{item}">
							<v-icon v-if="item.active">mdi-check</v-icon>
						</template>

						<template v-slot:item.action="{item}">
							<v-icon v-if="!filters.archived" @click="">
								mdi-archive
							</v-icon>

							<v-icon v-else @click="">
								mdi-backup-restore
							</v-icon>
						</template>
					</v-data-table>

					<v-pagination v-model="filters.page" :disabled="listStatus === status.RUNNING"
								  :length="products.pages" :total-visible="7" class="my-4"
								  color="secondary"></v-pagination>

				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import TableFilters from '@/components/global/TableFilters'

export default {
	name: 'ProductsIndex',
	components: {TableFilters},
	data: () => ({
		headers: [
			{text: 'Name', align: 'left', value: 'name', width: '20%'},
			{text: 'SKU', align: 'left', value: 'sku', width: '20%'},
			{text: 'Category', value: 'category', width: '25%'},
			{text: 'Active', value: 'active', align: 'center', width: '15%'},
			{text: 'Maintainer', value: 'lastMaintainedBy', width: '15%'},
			{text: '', value: 'action', align: 'right', width: '5%'},
		],
		filters: {},
	}),
	created() {
		this.filters = {...this.appliedFilters}
		this.listAllCategories()
	},
	methods: {
		...mapActions('accessory/product', [
			'listProducts',
			'applyFilters'
		]),
		...mapActions('accessory/category', [
			'listAllCategories'
		]),
		editProduct(id) {
			this.$router.push({name: 'Edit Product', params: {id: id}}).catch(() => {
			})
		},
		refreshData() {
			this.listProducts()
			this.listAllCategories()
		},
		categoryNameForId(id) {
			for (let i = 0; i < this.categories.data.length; i++) {
				if (this.categories.data[i].id === id) {
					return this.categories.data[i].name
				}
			}
			return ''
		}
	},
	computed: {
		...mapState('accessory/product', [
			'products',
			'appliedFilters',
			'listStatus',
			'createStatus',
			'archiveStatus',
		]),
		...mapState('accessory/category', [
			'categories',
		]),
		filterTypes() {
			return [
				{
					label: 'Name',
					type: 'text',
					name: 'name'
				},
				{
					label: 'SKU',
					type: 'text',
					name: 'sku'
				},
				{
					label: 'Category',
					type: 'select',
					name: 'category',
					itemText: 'name',
					itemValue: 'id',
					items: this.categories,
				},
				{
					label: 'Archived',
					type: 'toggle',
					name: 'archived',
					required: true,
				},
			]
		}
	},
	watch: {
		filters: {
			handler(updated) {
				this.applyFilters({...updated})
			},
			deep: true,
		}
	}
}
</script>

<style scoped>

</style>
