var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-md-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',_vm._b({},'v-card',_vm.customCard,false),[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products.data,"loading":_vm.listStatus === _vm.status.RUNNING,"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-t",attrs:{"id":"table-toolbar","flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.filters.archived ? 'Archived Products' : 'Products')+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.filters.archived,"color":"primary"},on:{"click":function($event){}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1),_c('table-filters',{attrs:{"filter-types":_vm.filterTypes,"right-margin":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.refreshData}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(!_vm.filters.archived)?_c('div',{staticClass:"text-decoration-underline clickable-text",on:{"click":function($event){return _vm.editProduct(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',[_vm._v(_vm._s(item.name))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.categoryNameForId(item.categoryId))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.filters.archived)?_c('v-icon',{on:{"click":function($event){}}},[_vm._v(" mdi-archive ")]):_c('v-icon',{on:{"click":function($event){}}},[_vm._v(" mdi-backup-restore ")])]}}])}),_c('v-pagination',{staticClass:"my-4",attrs:{"disabled":_vm.listStatus === _vm.status.RUNNING,"length":_vm.products.pages,"total-visible":7,"color":"secondary"},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }